



    .summary-steps {

     }

    .product-slideshow-nav {
        li {

            a {
                height: 50px;
                width: 70px;
                display: inline-block;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    .old-price {
        text-decoration: line-through;
    }

    .product-extra-info-switcher {
        border: 1px solid var(--global-border);
        border-top: none;
    }

    .builder-summary-step-separator {
        position: relative;
        height: calc(var(--global-margin) * 2);
        margin: calc(var(--global-margin) * 1);
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        .icon, hr {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        hr {
            position: relative;
            z-index: 1;
            margin: 0;
        }

        .icon {
            z-index: 2;
            border: calc(var(--global-margin) * 0.8) solid var(--global-inverse-color);
        }
    }


    .builder-progress{
        position: relative;
        z-index: 3;
    }

    .builder-main {
        position: relative;
        z-index: 2;
    }

    .builder-summary {
        position: relative;
        z-index: 2;
    }

    .builder-summary-card {
        position: relative;
        z-index: 10;
        max-height: calc(100vh - 170px );
    }

    .summary-header {
        padding-top: calc(var(--global-margin) * 0.65);
        padding-bottom: calc(var(--global-margin) * 0.65);
    }

    .step-summary-title {
        font-size: calc(var(--global-font-size));
        font-weight: bold;
        letter-spacing: -0.8px;
    }

    .icon-toggle {
        background: var(--global-inverse-color);
        padding: calc(var(--global-margin) * 0.1);
        border-radius: 50%;
        transition: background-color 150ms, color 150ms, transform 350ms ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: var(--global-primary-background);
            color: var(--global-inverse-color);
            transform: rotate(180deg);
        }

        .icon-toggle-icon {
            position: relative;
            top: 1px;
        }
    }

    .advantages-list li a {
        text-decoration: underline;
    }

    .packages-slider-inner{
        padding: 0 10px;
    }

    .package-box {
        .package-summary {
            .package-limit{
                font-size: calc(var(--global-font-size) * 2.4);

            }
            .content {
                margin-bottom: calc(var(--global-margin) * 2);
            }
        }
        .package-info-separator {
            margin-top: calc(var(--global-margin) * 1.5);
            margin-bottom: calc(var(--global-margin) * 1.5);

        }
    }

    .select-number-title {
        margin-bottom: calc(var(--global-margin) * 1.7);
    }

    .package-options-explain {
        margin-bottom: calc(var(--global-margin) * 1.5);
    }
    .discount-type-wrapper {
        position: relative;

        .overlay {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            cursor: not-allowed;
            display: block;
            background: rgba(255,255,255,0.6);
            z-index: 2;
        }
    }
